.hero-section{
    background-color: var(--neutral-1);
    color: var(--font-color-button);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    .hero-wrapper{
        @media #{$media-above-992} {
            height: 66vh;
            .hero-content{
                bottom: 56px;
                right: 52px;
                width: 456px;
            }
        }
        @media #{$media-below-992} {
            height: 100vh;
            .hero-content{
                top: 56%;
                left: 50%;
                transform: translate(-50%,-50%);
                width: 456px;
            }
        }
        position: relative;
        .hero-content{
            position: absolute;
            font-family: $roboto-regular !important;
            .hero-heading{
                // font-weight: 300;
                font-size: 52px;
                line-height: 40px;
                letter-spacing: 0.2px;
                margin-bottom: 16px;
            }
            p.description{
                margin-bottom: 40px;
            }
            p{
                // font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.2px;
                margin-bottom: 0;
            }
            
            @media #{$media-above-1280} {
                max-width:1280px;
            }
            @media #{$media-above-992-below-1280} {
                max-width:992px;
            }
            @media #{$media-above-768-below-992} {
                width:728px;
            }
            @media #{$media-above-576-below-768} {
                width:546px;
            }
            @media #{$media-below-576} {
                width:100%;
                padding-left:15px;
                padding-right:15px;
            }
        }
        .got-idea{
            position: absolute;
            bottom: -48px;
            right: 0;
        }
    }
}