.comment-info{
    display: flex;
    padding: 16px 16px;
    border-bottom: 1px solid var(--neutral-9);
    .img-wrapper{
        min-width: 48px;
        width: 48px;
        height: 48px;
        border-radius: 46px;
        overflow: hidden;
        margin-right: 16px;
        img{
            width: 100%;
        }
    }
    .comment-data{
        .line-1{
            .user-name{
                font-family: $roboto-bold;
            }
            .dot{
                margin: 0 8px;
                svg{
                    height: 4px;
                    width: 4px;
                }
            }
            .comment-time{
                color: var(--font-color-secondary);
                margin-left: 5px;
            }
        }
        .comment-content{
            font-family: $roboto-regular;
            font-size: 16px;
            color: var(--font-color-primary);
            p{
                margin-bottom: 0;
            }
        }
        .icons{
            font-family: $roboto-bold;
            color: var(--font-color-secondary);
            margin-top: 16px;
            .upvote-icon,.comment-icon,.reply-icon{
                margin-right: 16px;
                padding:8px 16px 8px 16px;
            }
            .upvote-icon.active{
                background-color: var(--primary-accent);
                border-radius: 12px;
                svg{
                    polyline,line{
                        stroke: var(--primary-default);
                    }
                }
                span{
                    color: var(--primary-default);
                }
            }
            svg{
                fill: var(--font-color-secondary);
                stroke: var(--font-color-secondary);
                height: 16px;
                polyline,line{
                    // fill: var(--font-color-secondary);
                    stroke: var(--font-color-secondary);
                }
            }
            span{
                margin-left: 10px;
            }
        }
    }
}