.vTree{
  padding-left:28px;
  position:relative;
  margin-top: 20px;
}
.verticalLine{
  content:'';
  width:1px;
  background:black;
  left:10px;
  top: -20px;
  position:absolute;
  z-index: 1;
}
