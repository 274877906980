.dept-tabs{
    margin-bottom: 40px;
    .nav-link{
        height: 40px;
        border: 1px solid var(--primary-default) !important;
        border-radius: 50px !important;
        color: var(--primary-default);
        margin-right: 8px;
        padding: 8px 16px;
    }
    .nav-link.active{
        background-color: var(--primary-default);
        color: #fff;
    }
    .nav-item{
        margin-bottom: 10px;
    }
}

.search{
    @media #{$media-above-1280} {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
    .input-group{
        @media #{$media-above-1280} {
            width: 455px;
        }
        @media #{$media-below-1280} {
            width: 100%;
            margin-bottom: 20px;
        }
        height: 48px;
        border: 1px solid #CBD4E1;
        border-radius: 999px;
        overflow: hidden;
        input{
            height: 100%;
        }
        button{
            padding: 15px 18px;
            display: flex;
        }
    }
}
.form-control:focus {
    box-shadow: none;
  }
  .form-control::placeholder {
    font-size: 0.95rem;
    color: #aaa;
    font-style: italic;
  }

.dept-data-loader{
    .sec-heading{
        width: 500px;
        height: 40px;
    }
    .nav-link{
        width: 80px;
        border: 0 !important;
    }
    .app .item{
        height: 200px;
        border-radius: 8px;
    }
}