.roadmap{
    background-color: #fff;
    border-radius: 8px;
    padding: 24px;
    margin-bottom: 24px;
    .roadmap-heading{
        color: var(--font-color-primary);
        margin-bottom: 0;
    }
    .date-arrow-wrapper{
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        display: -webkit-inline-box;
        .date{
            color: var(--font-color-secondary);
            font-family: $roboto-regular;
            right: 50px;
            top: 50%;
            transform: translateY(-50%);
            margin-right: 40px;
        }
        .arrow{
            transform: rotate(180deg);
            svg{
                height: 2rem;
                path{
                    stroke: var(--font-color-secondary);
                }
            }
        }
        .arrow.collapsed{
            transform: rotate(0deg) !important;
        }
    }
    .descBox{
        margin-top: 0px;
        p{
            margin-top: 16px;
            margin-bottom: 0;
            font-family: $roboto-regular;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.2px;
            color: var(--font-color-secondary);
        }
        img{
            width: 100%;
            margin: 25px 0;
        }
    }
}