.home-section{
    // margin-top: 88px;
}
.hero{
    @media #{$media-above-992} {
        height: 66vh;
    }
    @media #{$media-below-992} {
        height: 100vh;
    }
}
.bti-digital-products{
    padding-top: 76px;
    min-height: 500px;
}
.announcement{
    .announcement-list {
      flex-wrap: inherit;
      overflow-x: auto;
      padding-bottom: 24px;
      .ann-list-item{
        flex-shrink: 0;
        width: 381.33px;
        height: 280px;
        padding: 16px 0;
        border-radius: 8px;
        margin-right: 16px;
        border: 1px solid var(--neutral-10);
        &:hover{
            background: var(--neutral-11);
            border: 1px solid var(--primary-default);
            box-shadow: 0px 10px 15px rgba(15, 26, 42, 0.1);
            .prod-status-text{
                color: var(--primary-default);
            }
        }
        &:last-child{
            margin-right: 0;
        }
      }
    }
}
.apps-List .app{
    height: 200px;
    margin-bottom: 32px;
}
.policy-procedure{
    .pol-pro-list{
        flex-wrap: inherit;
        overflow-x: auto;
        padding-bottom: 24px;
        .pol-pro-item{
            flex-shrink: 0;
            width: 320px;
            height: 240px;
            padding: 16px;
            border-radius: 8px;
            margin-right: 16px;
            background-color: var(--neutral-12);
            &:hover{
                box-shadow: 0px 10px 15px rgba(15, 26, 42, 0.1);
            }
            &:last-child{
                margin-right: 0;
            }
        }
    }
}

.banner-loader{
    height: 100%;
}