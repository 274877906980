.article{
    position: relative;
    .left{
        @media #{$media-above-768} {
            position: absolute;
            left: 0;
            width: 500px;
            height: 243px;
        }
        @media #{$media-above-768-below-992} {
            width: 57%;
        }
        @media #{$media-below-768} {
            padding-top: 20px;
        }
        h3{
            color: var(--font-color-primary);
            margin-bottom: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .posted-by{
            display: flex;
            font-family: $roboto-regular;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.2px;
            color: var(--font-color-neutral);
            margin-bottom: 8px;
            .dot{
                margin: 0 8px;
                svg{
                    height: 4px;
                    width: 4px;
                }
            }
        }
        .content{
            font-family: $roboto-regular;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.2px;
            color: #63666A;
            margin-bottom: 20px;
            height: 120px;

            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 5; /* start showing ellipsis when 3rd line is reached */
            white-space: pre-wrap;
            & *{
                font-size: 16px !important;
                font-family: $roboto-regular !important;
                line-height: 24px !important;
                letter-spacing: 0.2px !important;
                color: #63666A !important;
                font-weight: 400  !important;
                margin-bottom: 0;
            }
        }
        .upvote-comment-share{
            font-family: $roboto-bold;
            display: flex;
            color: var(--primary-default);
            font-size: 18px;
            line-height: 24px;
            letter-spacing: 0.2px;
            .elem{
                @media #{$media-above-768} {
                    margin-right: 10px;
                }
                 padding:8px 16px 8px 16px;
                 svg,em{
                     margin-top: -2px;
                     height: 15px;
                     margin-right: 10px;
                     fill: var(--primary-default);
                     stroke: var(--primary-default);
                     path,line,polyline{
                         stroke: var(--primary-default);
                     }
                 }
            }
            .elem.upvote.active{
                background-color: var(--primary-accent);
                border-radius: 12px;
            }
        }
    }
    .right{
        width: 322px;
        height: 243px;
        @media #{$media-above-1280} {
            margin-left: 306px;
        }
        @media #{$media-above-992-below-1280} {
            margin-left: 26px;
        }
        @media #{$media-above-768-below-992} {
            width: 40%;
        }
        @media #{$media-above-768} {
            position: absolute;
            right: 0;
        }
        @media #{$media-below-768} {
            width: 100%;
        }
        img{
            width: 100%;
            height: 100%;
            border-radius: 8px;
            object-fit: cover;
        }
    }
}