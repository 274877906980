.top-div{
    font-family: $roboto-bold;
    position: relative;
    background:var(--primary-default);
    color: var(--font-color-button) !important;
    color: #fff;
    width:fit-content;
    text-align:center;
    padding:5px 10px;
    font-size:10px;
    margin:auto;
  }
  .emp-box{
    font-family: $roboto-bold;
    text-align:center;
    padding:5px;
    font-size:10px;
    position:relative;
  }
  .emp-box.active{
    background-color: var(--primary-default);
    color: var(--font-color-button);
  }
  .emp-box.not-active{
    background:#beb4b4;
    color: #000;
  }
  .horizontalBox.emp-box{
    width: max-content;
    min-width: 130px;
    height: 58px;
  }
  .horizontalBox.emp-box:before{
      position:absolute;
      content:'';
      height:18px;
      width:1px;
      background:black;
      z-index:2;
      top:-18px;
      left:50%;
      transform:translatex(-50%)
  }
  .verticalBox.emp-box{
    min-width: 120px;
    margin-top: 20px;
  }
  .verticalBox.emp-box:before{
    content:'';
    height:1px;
    width:17px;
    background:black;
    left:-17px;
    top:50%;
    transform:translatey(-50%);
    position:absolute;
  }
  .flag-wrapper{
    position: absolute;
   //   width: 30px;
     left: -10px;
     bottom: -15px;
     z-index: 2;
  }
  .flag{
      height: 30px;
      margin-right: 10px;
  }