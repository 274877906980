.prod-status{
    height: 100%;
    position: relative;
    display: block;
    .prod-img-wrapper{
        width: 100%;
        height: 200px;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .prod-status-text{
        position: absolute;
        text-align: center;
        width: 100%;
        bottom: 0;
        color: var(--font-color-primary);
        margin-bottom: 0;
    }    
}