.hTreeWrapper{
  position: relative;
  .horizontalLine:before{
    position: absolute;
    content: '';
    height: 17px;
    width: 1px;
    background: black;
    z-index: 2;
    top: 0;
    left: 50%;
    transform: translatex(-50%);
  }
  .horizontalLine{
    height:1px;
    background:black;
    margin-top:17px ;
    display: inline-block;
  }
  ul.hTree{
    list-style:none;
    display:inline-flex;
    column-gap:20px;
    padding: 0;
    margin-top: 10px;
    li.hLi{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}