.proj-back{
    .wrapper{
        width: 100%;
        @media #{$media-above-768} {
            display: flex;
        }
        @media #{$media-below-768} {
            display: block;
        }
        @media #{$media-above-1280} {
            column-gap: 264px;
        }
        @media #{$media-above-992-below-1280} {
            column-gap: 164px;
        }
        @media #{$media-above-768-below-992} {
            column-gap: 64px;
        }
        .left{
            flex: 1;
            // @media #{$media-above-768} {
            //     width: 696px;
            // }
            @media #{$media-below-768} {
                width: 100%;
            }
        }
        .right{
            @media #{$media-above-768} {
                flex: 0 0 216px;
                max-width: 216px;
            }
            @media #{$media-below-768} {
                width: 100%;
                padding-top: 30px;
            }
        }
    }
}
.people{
    .people-group-list {
      flex-wrap: inherit;
      overflow-x: auto;
      padding-bottom: 24px;
      .people-group{
        flex-shrink: 0;
        width: 279px;
        margin-right: 20px;
        &:last-child{
            margin-right: 0;
        }
      }
    }
}
.per-kpi-item{
    width: 337px;
    height: 308px;
    border: 1px solid var(--neutral-9);
    border-radius: 8px;
}

.performance-kpi{
    .per-kpi-list{
        flex-wrap: inherit;
        overflow-x: auto;
        padding-bottom: 24px;
        .per-kpi-item{
            flex-shrink: 0;
            width: 320px;
            height: 240px;
            padding: 16px;
            border-radius: 8px;
            margin-right: 16px;
            background-color: var(--neutral-12);
            &:hover{
                box-shadow: 0px 10px 15px rgba(15, 26, 42, 0.1);
            }
            &:last-child{
                margin-right: 0;
            }
        }
    }
}

.product-loader{
    .documentation{
        h3{
            height: 32px;
        }
        h4{
            height: 20px;
        }
    }
}