.peoples-wrapper{
    .title{
      font-size: 20px;
      font-family: $roboto-bold;
      margin-bottom: 16px;
    }
    .each-people{
      text-decoration: none;
      margin-bottom: 16px;
      display: flex;
      .img-wrapper{
        width: 64px;
        height: 64px;
        margin-right: 8px;
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 8px;
          color: var(--font-color-primary);
        }
      }
      .details{
        .name{
          font-family: $roboto-bold;
          color: var(--font-color-primary);
          font-size: 16px;
          line-height: 24px;
        }
        p{
          margin-bottom: 0;
          color:var(--font-color-secondary);
          font-size: 12px;
          line-height: 16px;
        }
        .profile{
          font-family: $roboto-bold;
        }
        .profileDept{
          font-family: $roboto-regular;
        }
      }
    }
}
// .each-people::-webkit-scrollbar {
//     width: 5px;
//   }
//   .each-people::-webkit-scrollbar-thumb {
//     background: #ddd; 
//     border-radius: 20px;
//   }