/*
BASE - base styles,  variables, typhography, etc
======================================================== */
@import './assets/scss/base/variables';
@import './assets/scss/base/base';
@import "./assets/scss/base/color";
@import "./assets/scss/base/buttons";
  
/*
PARTIALS - Header,  Masthead, Footer, etc
======================================================== */ 

/*
COMPONENTS - button,  form, slider, etc 
======================================================== */ 
@import "./assets/scss/components/header";
@import "./assets/scss/components/hero";
@import "./assets/scss/components/app-section";
@import "./assets/scss/components/each-app";
@import "./assets/scss/components/pol-pro";
@import "./assets/scss/components/top-section";
@import "./assets/scss/components/people";
@import "./assets/scss/components/roadmap";
@import "./assets/scss/components/article";
@import "./assets/scss/components/comment-each";
@import "./assets/scss/components/comment-list";
@import "./assets/scss/components/team-structure";
@import "./assets/scss/components/tree-part-horizontal";
@import "./assets/scss/components/tree-part-vertical";
@import "./assets/scss/components/each-user";
@import "./assets/scss/components/product-status";
@import "./assets/scss/components/right-sidebar";
@import "./assets/scss/components/head-desc";
@import "./assets/scss/components/share-popup";

/*
Pages - blog,  about, contact, etc 
======================================================== */ 
@import "./assets/scss/pages/home";
@import "./assets/scss/pages/product";
@import "./assets/scss/pages/department";
@import "./assets/scss/pages/blog";
  
//StoryBook
@import "./assets/themes/common.scss";
@import "./assets/themes/snl-complib.scss";
@import "./assets/themes/spacing.scss";
@import "./assets/themes/theme-colors.scss";
@import "./assets/themes/typography.scss";
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.3.0/css/all.min.css");
//
