* {
  margin: 0;
  box-sizing: border-box;
}

/* Scroller CSS */

.scroll::-webkit-scrollbar {
  width: var(--spacing-8);
}

.scroll::-webkit-scrollbar-track {
  background-color: var(--scroll-background);
  border-width: 0px 0.5px;
  border-style: solid;
  border-color: var(--scroll-border);
}

.scroll::-webkit-scrollbar-thumb {
  border-right: 2px solid transparent;
  border-left: 2px solid transparent;
  background-clip: padding-box;
  border-radius: var(--border-radius-8);
  background-color: var(--scroll-thumb-background);

  &:hover {
    background-color: var(--scroll-thumb-hover);
  }
}
