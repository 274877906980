.tree-section{
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 28px;
}
.tree-wrapper-main{
    padding-bottom: 16px;
    &.custom-scrollbar{
      overflow-x: auto;
      overflow-y: hidden;
    }
}
.tree-wrapper{
  width: 100%;
    display: table-caption;
    padding: 10px;
    transform-origin: top left;
}
.tree-bottom{
    margin-top: 20px;
    font-weight: 700;
    border-top: 1px solid gray;
    padding-top: 20px;
    .left span{
      margin-left: 20px;
    }
    .right{
      right: 0;
      top: 20px;
    }
}