:root {

  /**
  *Neutral Colors
  */

  --neutral-1: #0F1A2A;
  --neutral-2: #1E2A3B;
  --neutral-3: #27364B;
  --neutral-4: #475569;
  --neutral-5: #64748B;
  --neutral-6: #788496;
  --neutral-7: #94A3B8;
  --neutral-8: #CBD4E1;
  --neutral-9: #DBE3ED;
  --neutral-10: #F1F4F9;
  --neutral-11: #F6F8FC;
  --neutral-12: #FFFFFF;

  /**
  *Primary
  */

  --primary-default: #008ECF;
  --primary-hover: #006B9C;
  --primary-selected: #004869;
  --primary-disabled: #CBD4E1;
  --primary-accent: #E5F4FA;


  /**
  *Font Colors
  */

  --font-color-primary: #27364B;
  --font-color-secondary: #64748B;
  --font-color-neutral: #8392A8;
  --font-color-disabled: #B8BFCA;
  --font-color-button: #FFFFFF;

/**
*Pastel Colors
*/
--pastel-1: #FFADAD;
--pastel-2: #FEC89A;
--pastel-3: #7FC8F8;
--pastel-4: #E0D2FF;
--pastel-5: #FFE8B7;
--pastel-6: #C7F5E3;
--pastel-7: #DAD1CA;
--pastel-8: #FCF3D3;
--pastel-9: #ECCBD9;
--pastel-10: #AFEFF5;

/**
*Semantic Colors
*/

--semantic-1: #0C9E0B;
--semantic-2: #E8FCF1;
--semantic-3: #A5E1BF;
--semantic-4: #00401C;
--semantic-5: #FFA300;
--semantic-6: #FFF5D5;
--semantic-7: #FFDE81;
--semantic-8: #724B00;
--semantic-9: #CF0027;
--semantic-10: #FFEBEB;
--semantic-11: #FC9595;
--semantic-12: #8C0000;

/**
* Scroller Colors
*/

--scroll-background: #fafafa;
--scroll-border: #e8e8e8;
--scroll-thumb-background: #d9d9d9;
--scroll-thumb-hover: #7d7d7d;

/**
* backdrop background
*/

--backdrop-background: rgba(0,0,0,.32);
}
