.blog-page{
    margin-top: 120px;
}
.blog-heading{
    width: 75%;
    margin-bottom: 20px;
}
.upvote-share {
    display: flex;
    right: 0;
    top: 0;
    @media #{$media-above-768} {
        position: absolute;
    }
    @media #{$media-below-768} {
        padding-bottom: 20px;
    }
    .upvote-icon{
        position: relative;
        margin-right: 20px;
        padding:8px 16px 8px 16px;
        background-color: #dcdcdc;
        border-radius: var(--border-radius-4);
        width: 80px;
        svg{
            stroke: var(--neutral-6);
            height: 18px;
            left: 16px;
            path{
                fill: var(--neutral-6);
            }
        }
        span{
            margin-left: 15px;
            font-family: $roboto-bold;
            font-size: 23px;
            color: var(--neutral-6);
            left: 35px;
        }
        svg,span{
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
        }
    }
    .upvote-icon.active{
        background-color: var(--primary-accent);
        svg{
            stroke: var(--primary-default);
            path{
                fill: var(--primary-default);
            }
        }
        span{
            color: var(--primary-default);
        }
    }
}
.back-btn{
    margin-bottom: 20px;
    span{
        font-family: $roboto-bold;
        font-size: 16px;
        line-height: 24px;
        color: var(--font-color-secondary);
        margin-left: 12px;
    }
    svg{
        margin-bottom: 5px;
    }
}
.posted-time{
    span{
        font-family: $roboto-regular;
        font-size: 16px;
        line-height: 24px;
        color: var(--font-color-secondary);
        margin-left: 12px;
    }
    svg{
        margin-bottom: 5px;
    }
}
.blog-banner{
    margin-top: 35px;
    height: 50vh;
    overflow: hidden;
    img{
        height: 100%;
        object-fit: cover;
    }
}
.blog-body{
    h2{
        font-family: $roboto-bold;
        font-size: 40px;
        line-height: 47px;
    }
    .article-content{
        margin-top: 50px;
        img{
            width: 100%;
            height: 100%;
            margin: 25px 0;
        }
    }
    .row{
        margin-top: 50px;
        svg{
            height: 1rem;
            margin-right: 1rem;
        }
    }
    .posted-by{
        display: flex;
        margin-top: 20px;
        font-family: $roboto-bold;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.2px;
        color: var(--font-color-primary);
    }
}
.blog-footer{
    margin-top: 25px;
    .sec-heading3{
        margin-bottom: 16px;
    }
    &.collapse{
        display: none;
    }
    &.collapse.show,&.collapsing{
        display: block !important;
    }
    border-color: #CDC1C1;
    .username{
        margin-bottom: 10px;
        input{
            background-color: var(--neutral-11);
            height: 50px;
            padding: 13px 25px;
            border: 0;
        }
    }
    .editor{
        // height: 120px;
        background-color: var(--neutral-11);
        margin-bottom: 30px;
        border-bottom: 1px solid var(--primary-disabled);
        .post-comment button{
            background-color: var(--neutral-8);
        }
        &.focused{
            border-bottom: 1px solid var(--primary-default);
            .post-comment button{
                background-color: var(--primary-default);
            }
        }
        &.filled{
            border-bottom: 1px solid var(--font-color-primary);
            .post-comment button{
                background-color: var(--primary-default);
            }
        }
        .reply-to{
            font-family: $roboto-bold;
            padding: 12px 15px 0;
            .reply-content {
                background: #80808063;
                padding: 3px 10px;
                display: inline-block;
                border-radius: 50px;
                svg{
                    height: 16px;
                    margin-top: -2px;
                    margin-left: 10px;
                    cursor: pointer;
                }
            }
        }
        quill-editor{
            display: flex;
            flex-direction: column-reverse;
            .ql-toolbar, .ql-container{
                border: 0;
            }
            .ql-container{
                height: 80px;
                .textarea-emoji-control{
                    display: none;
                }
            }
            .ql-toolbar{
                height: 42px;
                padding-left:15px;
                .ql-formats{
                    margin: 0;
                }
            }
        }
        .post-comment{
            position: absolute;
            bottom: 12px;
            right: 16px;
        }
    }
    .show-all-comments{
        font-family: $roboto-bold;
        font-size: 16px;
        color: var(--primary-default);
        text-align: center;
        margin: 20px 0;
    }
}

.blog-loader{
    .back-btn{
        width: 62px;
        height: 24px;
    }
    .blog-heading{
        width: 50%;
        height: 40px;
    }
    .upvote{
        width: 80px;
        height: 48px;
        margin-right: 20px;
        border-radius: var(--border-radius-4);
    }
    .share{
        width: 130px;
        height: 48px;
        border-radius: var(--border-radius-4);
    }
    .posted-time{
        width: 115px;
        height: 24px;
    }
    .posted-by{
        width: 150px;
        height: 24px;
        margin-bottom: 25px;
    }
}