.documentation{
    h3{
        color: var(--font-color-primary);
        margin-bottom: 24px;
    }
    .resource-list{
        font-family: $roboto-bold;
        line-height: 24px;
        letter-spacing: 0.2px;
        h4{
            font-size: 16px;
            margin-bottom: 10px;
            color: var(--font-color-secondary);
        }
        ul{
            list-style: none;
            padding-left: 0;
            margin-bottom: 12px;
            li{
                margin-bottom: 8px;
                a{
                    font-size: 14px;
                    color: var(--primary-default);
                    text-decoration: underline;
                    
                    display: inherit;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    transition: 0.3s;
                    &:hover{
                        white-space: normal;
                        overflow: visible;
                    }
                }
            }
        }
    }
    // .list{
    //     display: grid;
    //     a{
    //         font-family: $roboto-bold;
    //         font-size: 14px;
    //         line-height: 24px;
    //         letter-spacing: 0.2px;
    //         color: var(--primary-default);
    //         text-decoration: underline  ;
    //         &:hover{
    //             color: var(--font-color-secondary);
    //             text-decoration: none;
    //         }
    //     }
    // }
}