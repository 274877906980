header{
    margin: 0 auto;
    display: block;
    width: 100%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background: rgba(255, 255, 255, 0.85);
    nav{
        .logo{
            min-width: 158px;
        }
        height: 88px;
        padding: 32px 32px 16px 32px;
        max-width: 1440px;
        margin: auto;
        @media #{$media-below-576} {
            padding: 32px 15px 16px 15px;
        }
        .siteTitle, .header__vertical-rule{
            @media #{$media-below-992} {
                display: none;
            }
        }
        .loggedInUser{
            display: flex;
            position: relative;
            .username{
                font-family: $roboto-bold;
                @media #{$media-below-992} {
                    display: none;
                }
            }
            .username-mobile{
                font-family: $roboto-bold;
                @media #{$media-above-992} {
                    display: none;
                }
            }
            .img-wrapper {
                min-width: 30px;
                width: 30px;
                height: 30px;
                border-radius: 46px;
                overflow: hidden;
                margin-left: 10px;
                margin-top: -5px;
                img {
                    width: 100%;
                }
            }
        }
        .header-loading{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .logo{
                height: 28px;
                min-width: 158px;
            }
            .header-vertical-line{
                border: 1px solid var(--neutral-9);
                margin: 0 var(--spacing-24);
                height: 28px;
            }
            .header-text{
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                .title{
                    height: 28px;
                    width: 464px;
                }
                .loggedInUser{
                    display: flex;
                    position: relative;
                    .username{
                        width: 120px;
                        height: 28px;
                    }
                }
            }
        }
    }
}