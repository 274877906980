.each-policy{
    margin: 0 auto;
    width: 100%;
    height: 100%;
    .img-wrapper{
        height: 160px;
        width: 100%;
        img{
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 8px;
        }
    }
    .heading{
        position: absolute;
        width: 100%;
        bottom: 0;
        color: var(--font-color-primary);
        margin-bottom: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}