.each-app{
    width: 100%;
    height: 100%;
    border: 1px solid var(--neutral-9);
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    &:hover{
      box-shadow: 0px 0px 20px rgba(15, 26, 42, 0.15);
    }
    .app-wrapper {
        overflow-y: auto;
        max-height: 85%;
        padding: 16px 24px;
        &::-webkit-scrollbar {
          width: 5px;
        }
        &::-webkit-scrollbar-track {
          // background: #fff;
        }
        &::-webkit-scrollbar-thumb {
          background: #ddd; 
          border-radius: 20px;
        }
        .app-content{
            h4{
              color: var(--font-color-primary);
              margin-bottom: 8px;
            }
            p{
              font-family: $roboto-regular;
              color: var(--font-color-primary);
              font-size: 16px;
              line-height: 24px;
              letter-spacing: 0.2px;
            }
            .learn-more{
                color:var(--primary-default);
                font-family: $roboto-bold;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.2px;
                position: absolute;
                inset: auto auto 0 0;
                padding: 16px 24px;
                background-color: var(--neutral-12);
                width: 100%;
            }
        }
    }
}
