//By akshay
html, body{
    overflow-x: hidden !important;
}
.body-copy, html, body {
    font-size: 14px;
    line-height: 24px;
}
body.modal-open{
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .border-bottom-1{
    border-bottom: 1px solid;
  }
  .box-shad-1{
    box-shadow: 0px 1px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .no-data-found{
    font-family: var(--font-family-roboto-bold), sans-serif;
    font-size: 25px;
    line-height: 40px;
    letter-spacing: 0.2px;
    color: var(--font-color-primary);
    text-align: center;
  }

.font-regular{
    font-family: $roboto-regular;
}
.font-bold{
    font-family: $roboto-bold;
}
.container1{
    @media #{$media-above-1280} {
        max-width:1280px;
    }
    @media #{$media-above-992-below-1280} {
        max-width:992px;
    }
    @media #{$media-above-768-below-992} {
        width:768px;
    }
    @media #{$media-above-576-below-768} {
        width:576px;
    }
    @media #{$media-below-576} {
        width:100%;
        padding-left:15px;
        padding-right:15px;
    }
}
.container2{
    @media #{$media-above-1280} {
        max-width:1180px;
    }
    @media #{$media-above-992-below-1280} {
        max-width:900px;
    }
    @media #{$media-above-768-below-992} {
        width:720px;
    }
    @media #{$media-below-768} {
        width:100%;
        padding-left:15px;
        padding-right:15px;
    }
}
.container3{
    width: 936px;
    @media #{$media-above-992} {
        max-width:936px;
    }
    @media #{$media-above-768-below-992} {
        width:720px;
    }
    @media #{$media-below-768} {
        width:100%;
        padding-left:15px;
        padding-right:15px;
    }
}
.container1,.container2,.container3{
    margin-left: auto;
    margin-right: auto;
}
.sec-heading{
    font-family: $roboto-bold;
    font-size: 36px;
    line-height: 40px;
    letter-spacing: 0.2px;
    color: var(--font-color-primary);
    margin-bottom: 40px;
}
.sec-heading2{
    font-family: $roboto-bold;
    font-size: 26px;
    line-height: 40px;
    letter-spacing: 0.2px;
    color: var(--font-color-primary);
    margin-bottom: 16px;
}
.sec-heading3{
    font-family: $roboto-bold;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.2px;
}
.sec-pad{
    padding-top: 56px;
    padding-bottom: 56px;
}
.sec-pad2{
    @media #{$media-above-1280} {
        padding-top: 96px;
        padding-bottom: 96px;
    }
    @media #{$media-above-992-below-1280} {
        padding-top: 75px;
        padding-bottom: 75px;
    }
    @media #{$media-above-768-below-992} {
        padding-top: 55px;
        padding-bottom: 55px;
    }
    @media #{$media-below-768} {
        padding-top: 35px;
        padding-bottom: 35px;
    }
}
.h-scrollbar::-webkit-scrollbar {
    height: 7px;
}
.h-scrollbar::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 8px;
}
.h-scrollbar.scrollbar-bg-grey::-webkit-scrollbar-track {
    background: var(--neutral-10);
    border-radius: 8px;
}
.h-scrollbar::-webkit-scrollbar-thumb {
    background: #ddd; 
    border-radius: 8px;
}

//used in product and department component
.individual-article{
    padding: 32px 24px;
    background: var(--neutral-12);
    border: 1px solid var(--neutral-9);
    border-radius: 16px;
    margin-bottom: 32px;
    @media #{$media-above-768} {
        height: 307px;
    }
}

.Stolt-Skeleton{
    min-height: 12px;
    display:flex;
    cursor: wait !important;
    flex-direction:column;
    background: #D9D9D9;
    background: rgba(130, 130, 130, 0.2);
    background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
    background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
    background-size: 2000px 200px;
    animation: wave-squares 2s infinite ease-out;
}
@keyframes wave-squares {
    0% {
        background-position: -1000px 0;
    }
    100% {
        background-position: 1000px 0;
    }
}