.WhatWeDo{
    .wrapper{
        @media #{$media-above-768} {
            display: flex;
        }
        @media #{$media-below-768} {
            display: block;
        }
        .left{
            @media #{$media-above-768} {
                width: 696px;
            }
            @media #{$media-below-768} {
                width: 100%;
            }
        }
        .right{
            @media #{$media-above-1280} {
                margin-left: 264px;
            }
            @media #{$media-above-992-below-1280} {
                margin-left: 164px;
            }
            @media #{$media-above-768-below-992} {
                margin-left: 64px;
            }
            @media #{$media-above-768} {
                width: 216px;
            }
            @media #{$media-below-768} {
                width: 100%;
                padding-top: 30px;
            }
        }
    }
}

.department-products{
    .product-list{
        flex-wrap: inherit;
        overflow-x: auto;
        padding-bottom: 24px;
        .product-item{
            flex-shrink: 0;
            width: 320px;
            height: 240px;
            margin-right: 16px;
            &:last-child{
                margin-right: 0;
            }
        }
    }
}

.dept-loader{
    .documentation{
        h3{
            height: 32px;
        }
        h4{
            height: 20px;
        }
    }
}